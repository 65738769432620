<template>
<div class="container">

    <el-form ref="registerForm" 
            :model="registerForm" 
            :rules="registerRules" > 
    <div class="registerbox">
      <div class="logo">
        <img src="../../assets/img/logo3.png" style="width:650px;height:auto">
      </div>
      <div class="emailbox"> 
        <div class="iconfont">&#xe610;</div> 
        <el-col :span="15">
          <el-form-item prop="email">
          <el-input ref="email" v-model="registerForm.email" placeholder="邮箱" type="text" id="email" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-button v-show="!sendingEamil" type="success" plain @click="submitEmail"> 发送邮件验证 </el-button>
          <el-button v-show="checkingEmail" type="success" plain :loading="true">检查邮箱</el-button>
          <el-button v-show="sendingEamil && !checkingEmail" type="success" plain :loading="true">已发送({{RetryCount}})</el-button>
        </el-col>
      </div>
      <br>          
      <div class="phonebox"> 
        <div class="iconfont">&#xe60b;</div> 
        <el-form-item prop="phone">
          <el-input  ref="phone" v-model="registerForm.phone" placeholder="请输入手机号" type="text" id="phone"   />
        </el-form-item>   
      </div>
      <br>

      <div class="userbox"> 
        <div class="iconfont">&#xe8c8;</div> 
        <el-form-item prop="username">
        <el-input  ref="username" v-model="registerForm.username" placeholder="用户名" type="text" id="user"   />
        </el-form-item>   
      </div>
      <br>

      <div class="pwdbox">
        <span class="iconfont">&#xeb6a;</span>
        <el-form-item prop="password">
          <el-input  ref="password" v-model="registerForm.password" placeholder="密码" type="password" id="password" show-password/>
        </el-form-item>   
        <el-input  v-show="false" ref="md5password" v-model="registerForm.md5password" type="password" id="md5pwd"/><br/>
      </div>
      <br>

      <div class="vcodebox">
        <span class="iconfont">&#xe624;</span>
        <el-col :span="15">
        <el-input ref="verifyCode" 
                  v-model="registerForm.verifyCode" 
                  placeholder="验证码将会发送到您的邮箱" 
                  oninput="value=value.replace(/\D/g,'')"
                  maxlength="6"
                  type="text"
                  show-word-limit
                  id="verifyCode"/>
        </el-col>
      </div>
      <br>

      <div class="register-box">
        <!-- style="width:100%;background: #1373d3" -->
        <el-button type="primary"  class="redirect_login_btn" @click="redirect_login">已有账号请点击登陆</el-button>
        <el-button type="primary"  class="register_btn"   @click="handleRegister">立即注册</el-button>
      </div>
      <br>

    </div>
    </el-form>
</div>
</template>

<script>
import router from "@/router"
import {Register,sendEmailCode,checkEmail} from "@/utils/api/index"
import { sha256 } from 'js-sha256'
import { transformWithProjections } from 'ol/proj'

export default {
  name: "Register",
  mounted() {
  },

  data: function () {
    // rule参数是占位用的，可以不用，必须要有，否则无法匹配callback函数
    var validatePassword = (rule,value,callback) => {
      if (value.length < 6) {
        callback(new Error('密码不能少于6位'))
      }else{
        callback();
      }
    };
    var validateUsername = (rule,value,callback) => {
      if (value.length >7 ) {
        callback(new Error('用户名不能超过7个字符'))
      } else {
        callback();
      }
    };

    var validateEmail = (rule,value,callback) => {
      if (!(/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(value))) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback();
      }
    };

    var validatePhone = (rule,value,callback) => {
      if (!(/^[1][3,4,5,7,8][0-9]{9}$/.test(value))) {
        callback(new Error('手机号格式错误'))
      } else {
        callback();
      }
    };

    return {
      sendingEamil:false,
      checkingEmail:false,
      RetryCount:60,
      timer:null,
      registerForm: {
        email: "1783939919@qq.com",
        username: "zzp",
        password: "123456",
        verifyCode:"",
        phone:""
      },
      registerRules: {
        email:[{ required: true, trigger: 'blur', validator: validateEmail}],
        username: [{ required: true, trigger: 'blur', validator: validateUsername}],
        password: [{ required: true, trigger: 'blur', validator: validatePassword}],
        phone:[{ required: false, trigger: 'blur', validator: validatePhone}]
      },
    }
  },
  methods: {
    redirect_login(){
      this.$router.replace({path: '/'});
    },

    submitEmail(){
      console.log("this is checkeEmail")
      this.$refs.registerForm.validateField("email",(emailerror) => {
        if (!emailerror) {
          this.sendingEamil = true
          this.checkingEmail = true
          let params = {
            "email":this.registerForm.email
          }
          checkEmail(params)
          .then(res=>{
            if(res.data.resultCode == 1)
            {
              // 如果没被注册 直接调用发送验证码函数，
              // 本可以套一起，但是套一起太乱了，分开写
              // 为了看起来好一点，搞个延迟s
              setTimeout(()=>{this.sendEmail()},2000)
            }else{
              this.checkingEmail = false
              this.sendingEamil = false
              this.$notify.warning({
                title: 'warning',
                message: '邮箱已被注册'
              })
              return
            }    
          })
        }else{
          console.log("邮箱格式错误")
          return
        }
      })
    },

    // 发送email
    sendEmail() {
      console.log("this is sendEmail")
      this.$refs.registerForm.validateField("email",(emailerror) => {
        if (!emailerror) {

          // 倒计时 TIME_COUNT 秒后再发送
          const TIME_COUNT = 30; 
          if (!this.timer) {
            this.RetryCount = TIME_COUNT;

            // 两个变量都要控制
            this.checkingEmail = false
            this.sendingEamil = true;
            // setInterval函数，计时处理，每秒 RetryCount--
            this.timer = setInterval(() => {
                if (this.RetryCount > 1 && this.RetryCount <= TIME_COUNT) {
                  this.RetryCount--;
                } else {
                  this.sendingEamil = false;
                  clearInterval(this.timer);
                  this.timer = null;
                }
            }, 1000)
          }
          let params = {
            "toEamil":this.registerForm.email
          }
          sendEmailCode(params)
          .then(res=>{
            if(res.data.resultCode == 0){
              console.log("验证码发送成功")
            }
          })
          .catch(err=>{
            this.$notify.warning({
              title: 'Warning',
              message: '服务器或回包错误'
            })
          })
        }else{
          console.log("邮箱格式错误")
          return
        }
      })
    },

    // 发送注册页表
    handleRegister(){
      this.$refs.registerForm.validate(valid => {
        if(valid){
          this.registerForm.md5password = sha256(this.registerForm.password)
          let params = {
            "username":this.registerForm.username,
            "password":this.registerForm.md5password,
            "email":this.registerForm.email,
            "phone":this.registerForm.phone,
            "verifyCode":this.registerForm.verifyCode
          }
          Register(params)
          .then(res=>{
            let resultData = res.data.resultData
            console.log(resultData)
            if(res.data.resultCode == 0){
                this.$message({
                   message: "注册成功，请登录",
                   type: "success",
                   duration: 1200,
                 });
                setTimeout(() => {
                  //登陆成功,跳转到登陆
                  router.push("/")
                  return
                }, 800);
              }else{
                this.$notify.warning({
                  title:"warning",
                  message: "注册失败",
                })
              }
          })
          .catch(err=>{
              console.log("服务器或前端处理错误")
              this.$notify.warning({
                title:"warning",
                message: "注册失败",
              })
          })
        }

      })
    }
  }


}
</script>


<style scoped>
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.container{
  height: 100vh;
  width: 100%;
  background: url("../../assets/img/warship.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  overflow: hidden;
}

.registerbox{
    display:block;
    position:absolute;
    width:900px;
    height:630px;
    top:40%;
    left:50%;
    transform:translate(-50%,-50%); 
    border-radius: 25px;
    background-color:rgba(0, 0, 0, 0.35);
    box-shadow: 0 12px 16px 0  rgba(87, 92, 146, 0.24), 0 17px 50px 0 #404668; 
}

.logo{
    display: table-cell;
    /* 垂直居中 */
    vertical-align: middle;
    /* 水平居中 */
    text-align: center;
    width: 900px;
    height: 130px;
}


.emailbox{
    opacity:0.9;
    margin-top:30px;
    height:45px;
    width:400px;
    display: flex; 
    margin-left:250px;
    background-color: white;
    border-radius: 10px;
}

.phonebox{
    opacity:0.9;
    margin-top:12px;
    height:45px;
    width:400px;
    display: flex; 
    margin-left:250px;
    background-color: white;
    border-radius: 10px;
}

.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
    margin-left: 3px;
}

.el-button--success.is-plain {
    color: #1b4008;
}

.userbox{
    opacity:0.9;
    margin-top:12px;
    height:45px;
    width:400px;
    display: flex; 
    margin-left:250px;
    background-color: white;
    border-radius: 10px;
}

.pwdbox{
    opacity:0.9;
    margin-top:12px;
    height:45px;
    width:400px;
    display: flex; 
    margin-left:250px;
    background-color: white;
    border-radius: 10px;
}

.vcodebox{
    opacity:0.9;
    margin-top:12px;
    height:45px;
    width:400px;
    display: flex; 
    margin-left:250px;
    background-color: white;
    border-radius: 10px;
}


*>>>.el-form-item--small .el-form-item__error {
  padding-top: 12px;
}
*>>>.el-form-item__error {
  font-size: 12px;
}

/* 去除el-input的边框 */
*>>>.el-input__inner{
  font-size: 15px;
  margin-top: 6px;
  border: none;
}
.el-input .el-input__count{
  margin-top: 3px;
  font-size: 14px;
}
.el-button--success.is-plain {
    color: #377817;
    background: #ecfae5;
    border-color: #bde5a8;
    margin-top: 6px;
}
.el-button--small {
    padding: 9px 15px;
    font-size: 13px;
    border-radius: 3px;
}

.register-box{
    font-size:16px;
    display: flex;
    justify-content: space-between ;
    width:225px;
    height: 50px;
    margin-left:260px;
    color:#4E655D;
    margin-top: 20px;
    align-items: center;
}
.register-box-text{
    color:#d6d5d5;
    font-size:16px;
    text-decoration:none;
    }


.redirect_login_btn{
  background-color: transparent; 
  text-decoration:underline;
  border: none;
  text-decoration: none;
  color: #e2e0e0;
  font-size: 16px;
  margin-right: 50px;
  display: flex;
}
.redirect_login_btn:hover{
  background-color: transparent; 
  font-weight:bold; 
  cursor: pointer;
}

.register_btn{
    background-color: #1373d3; /* Green */
    border: none;
    padding: 12px 38px;
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    border-radius: 20px;
    font-weight:bold;
    outline:none;
}
.register_btn:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); 
    cursor: pointer;
    background-color: #085eb5;
    transition: all 0.2s ease-in;
}
.el-input{
  font-size:15px;
  color:#062280;
  font-weight:bold;
}


@font-face {
  font-family: "iconfont";
  src: url('../../assets/css/iconfont/iconfont.woff2?t=1668758935728') format('woff2'),
       url('../../assets/css/iconfont/iconfont.woff?t=1668758935728') format('woff'),
       url('../../assets/css/iconfont/iconfont.ttf?t=1668758935728') format('truetype');
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 27px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color:#031654;
    margin-left:15px;
    margin-right:15px;
    margin-top:8px;
}

.icon-yanzhengyanzhengma:before {
  content: "\e624";
}

.icon-yuechi_o:before {
  content: "\eb6a";
}

.icon-yonghu-xianxing:before {
  content: "\e8c8";
}

.icon-youxiang1:before {
  content: "\e610";
}

.icon-dianhua:before {
  content: "\e60b";
}


</style>

